<template>
  <div>
    <!-- <div v-html="newshtml"></div> -->
  </div>
</template>

<script>
import newspic1 from "@/assets/images/newsimg/国庆新闻.jpg";
import newspic2 from "@/assets/images/newsimg/中秋.jpg";
import newspic3 from "@/assets/images/newsimg/考察.jpg";
import newspic4 from "@/assets/images/newsimg/四川考察.jpg";
import newspic5 from "@/assets/images/newsimg/30亿元.png";
import newspic6 from "@/assets/images/newsimg/会见董事长.jpg";
import newspic7 from "@/assets/images/newsimg/抗疫物资.jpg";
import newspic8 from "@/assets/images/newsimg/华为.jpg";
import newspic9 from "@/assets/images/newsimg/9周年.jpg";
export default {
  data() {
    return {
      newshtml: "",
      newList: [
        {
          id: 1,
          title: "举国同庆 盛世华诞",
          date: "2022-10-01",
          imgurl: newspic1,
          desc: "https://mp.weixin.qq.com/s/C7C7a30Sc6E1C0siNpUyYw",
        },
        {
          id: 2,
          title: "月满金秋 阖家团圆",
          date: "2022-09-10",
          imgurl: newspic2,
          desc: "https://mp.weixin.qq.com/s/sele8v4Jdwg0t8wfgD42SQ",
        },
        {
          id: 3,
          title: "贵州省六盘水市水城区委副书记、区长赵庆强率团莅临卓阳集团考察",
          date: "2022-09-05",
          imgurl: newspic3,
        },
        {
          id: 4,
          title: "四川省遂宁市许文强副市长率团莅临卓阳总部考察洽谈",
          date: "2022-09-04",
          imgurl: newspic4,
        },
        {
          id: 5,
          title: "卓阳集团与射洪市签署30亿元新能源项目投资协议",
          date: "2022-08-28",
          imgurl: newspic5,
        },
        {
          id: 6,
          title: "唐德智会见卓阳能源集团董事长黄俊一行",
          date: "2022-08-26",
          imgurl: newspic6,
        },
        {
          id: 7,
          title: "西藏抗疫 卓阳同行丨卓阳集团抗疫物资紧急驰援西藏日喀则",
          date: "2022-08-24",
          imgurl: newspic7,
        },
        {
          id: 8,
          title: "卓阳集团与华为能源技术公司开展技术交流会",
          date: "2022-06-22",
          imgurl: newspic8,
        },
        {
          id: 9,
          title: "卓阳集团九周年",
          date: "2022-06-21",
          imgurl: newspic9,
        },
      ],
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    this.newshtml = this.newList[this.$route.params.id - 1].desc;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>